<template>
    <div class="container">

        <div>
            <b-tabs content-class="mt-3">
                <b-tab title="Batches" active>
                    <batch-list :companyId="companyId"></batch-list>
                </b-tab>
                <b-tab title="By Type">
                        <type-report :companyId="companyId"></type-report>
                </b-tab>
            </b-tabs>
        </div>


    </div>
</template>

<script>
import BatchList from "./BatchList";
import TypeReport from "./TypeReport";

export default {
    name: "CustomerPage",
    components: {
        BatchList,
        TypeReport
    },
    props: {
        cid: String
    },
    data() {
        return {
            companyId: ''
        }
    },
    created() {
        this.companyId = JSON.parse(this.cid);

    },
    mounted() {
        this.$root.$emit('batch-list-activate');


    }
}
</script>
