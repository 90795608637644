require('./bootstrap');
require('./base/bootstrap-vue');
require('./base/vue-multiselect');
require('./base/vue-confirm');
require('./base/loadash');
require('./base/vuejs-datepicker');
require('./base/cookies');
require('./base/filters');
require('./base/mixins');

window.Vue = require('vue').default;

Vue.component('admin', require('./components/Admin').default);
Vue.component('admin-batch-detail', require('./components/AdminBatchDetail').default);
Vue.component('admin-users', require('./components/AdminUsers').default);
Vue.component('batch-detail', require('./components/BatchDetail').default);
Vue.component('backdoor-entry', require('./components/BackdoorEntry').default);
Vue.component('customer-page', require('./components/CustomerPage').default);


const app = new Vue({
    el: '#app',
});

