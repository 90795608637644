<template>
    <div>
        <div class="row header1">
            <div class="col-12">
                Folio No: {{ folioNumber }}
            </div>
        </div>
        <div class="row">
            <table class="col-12">
                <tr class="header">
                    <th>Hotel</th>
                    <th>Type</th>
                    <th>Paid By</th>
                    <th>Reference</th>
                    <th>Check #</th>
                    <th>Deposit Date</th>
                    <th>Deposit Amount</th>
                </tr>
                <template v-for="item in batch">
                    <tr v-if="item.rowType == 'hotel-header'" class="hotel-header">
                        <td colspan="7">{{ item.hotelId }}</td>
                    </tr>
                    <tr v-else-if="item.rowType == 'data-row'" class="data-row">
                        <td></td>
                        <td>{{ item.transaction_type }}</td>
                        <td>{{ item.paid_by }}</td>
                        <td>{{ item.reference }}</td>
                        <td>{{ item.check_number }}</td>
                        <td>{{ item.deposit_date }}</td>
                        <td class="currency">{{ item.deposit_amount |currency }}</td>
                    </tr>
                    <tr v-else-if="item.rowType == 'type-footer'" class="type-footer">
                        <td colspan="2"></td>
                        <td colspan="3">Summary for transaction type {{ item.transaction_type }} ({{ item.numRecords }}
                            records)
                        </td>
                        <td colspan="2" class="currency" style="padding-right: 20px;">
                            {{ item.totalAmount | currency }}
                        </td>
                    </tr>
                    <tr v-else-if="item.rowType == 'hotel-footer'" class="hotel-footer">
                        <td colspan="6" class="smaller">Summary for Hotel: {{ item.hotelId }} ({{ item.numRecords }} records)</td>
                        <td class="currency">{{ item.totalAmount | currency }}</td>
                    </tr>

                </template>
                <tr class="footer">
                    <td colspan="2">Grand Total</td>
                    <td colspan="4"></td>
                    <td class="currency">{{ total | currency }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "BatchDetail.vue",
    props: {
        'data': String
    },
    data() {
        return {
            companyId: null,
            folioNumber: null,
            batch: [],
            total: 0,
        }
    },
    methods: {
        collectionItems(batch) {
            let rows = [];
            let batchKeys = Object.keys(batch);
            for (let x = 0; x < batchKeys.length; x++) {
                const hotelId = batchKeys[x]
                const hotel = batch[hotelId];
                rows.push(this.hotelHeaderRow(batchKeys[x]));
                const types = Object.keys(hotel.types);
                for (let y = 0; y < types.length; y++) {
                    const type = types[y];
                    const typeRow = hotel.types[type];
                    const transactions = typeRow.transactions;
                    for (let z = 0; z < transactions.length; z++) {
                        rows.push(this.dataRow(transactions[z]));
                    }
                    rows.push(this.typeFooterRow(type, typeRow.num, typeRow.total))
                }
                rows.push(this.hotelFooterRow(hotelId, hotel.num, hotel.total));
            }
            return rows;
        },
        hotelHeaderRow(hotelId) {
            return {
                'rowType': 'hotel-header',
                'hotelId': hotelId
            }
        },
        hotelFooterRow(hotelId, numRecords, totalAmount) {
            return {
                rowType: 'hotel-footer',
                hotelId: hotelId,
                numRecords: numRecords,
                totalAmount: totalAmount
            }
        },
        typeFooterRow(type, numRecords, totalAmount) {
            return {
                rowType: 'type-footer',
                transaction_type: type,
                numRecords: numRecords,
                totalAmount: totalAmount
            }
        },
        dataRow(transaction) {
            let row = JSON.parse(JSON.stringify(transaction));
            row['rowType'] = 'data-row';
            return row;

        }

    },
    mounted() {
        let jsonData = JSON.parse(this.data);
        console.log(jsonData);
        this.companyId = jsonData.companyId;
        this.folioNumber = jsonData.folioNumber;
        this.total = jsonData.total;
        this.batch = this.collectionItems(jsonData.batch);
    }
}
</script>

<style scoped>
.header1 {
    background-color: rgba(29, 104, 167, 0.50);
    font-weight: bold;
    margin-bottom: 10px;
}

tr.hotel-header {
    background-color: #cccccc;
    font-weight: bold;
}

tr.header {
    background-color: #333;
    color: #fff;
    font-weight: bold;
}

tr.data-row {
    background-color: #fff;
}

tr.type-footer {
    background-color: #ddd;
    font-style: italic;
    color: #666;

}
.smaller
{
    font-size: 10px !important;
}
tr.type-footer td
{
    font-size: 10px !important;
}

tr.hotel-footer {
    background-color: #c5c5c5;
    margin-bottom: 20px;
}

tr.footer {
    background-color: rgba(29, 104, 167, 0.50);
    font-weight: bold;
    margin-bottom: 10px;
}

td.currency {
    text-align: right;
}
</style>
