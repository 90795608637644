<template>
    <div>
        <b-overlay :show="state==='loading'">
            <b-form @submit="saveEntry">
                <b-row>
                    <b-col>
                        <b-form-group label="Hotel:">
                            <b-form-select
                                v-model="transaction.hotel_id"
                                :options="hotelList"
                                :class="{error: isError('hotel_id')}"
                            ></b-form-select>
                        </b-form-group>
                        <b-form-group label="Paid By:">
                            <b-form-input
                                type="text"
                                v-model="transaction.paid_by"
                                placeholder="Paid By"
                                :class="{error: isError('paid_by')}"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Transaction Type:">
                            <b-form-select
                                v-model="transaction.transaction_type"
                                :options="transactionTypes"
                                :class="{error: isError('transaction_type')}"
                            ></b-form-select>
                        </b-form-group>

                        <b-form-group label="Reference:">
                            <b-form-input
                                type="text"
                                v-model="transaction.reference"
                                placeholder="reference"
                                :class="{error: isError('reference')}"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Check Number:">
                            <b-form-input
                                type="text"
                                v-model="transaction.check_number"
                                placeholder="Check Number"
                                :class="{error: isError('check_number')}"
                            ></b-form-input>
                        </b-form-group>


                        <b-form-group label="Deposit Amount:">
                            <b-form-input
                                type="number"
                                v-model="transaction.deposit_amount"
                                placeholder="Deposit Amount"
                                :class="{error: isError('deposit_amount')}"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Deposit Date:">
                            <b-form-input
                                type="date"
                                v-model="transaction.deposit_date"
                                :class="{error: isError('deposit_amount')}"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Notes:">
                            <b-form-textarea
                                v-model="transaction.notes"
                            ></b-form-textarea>
                        </b-form-group>
                        <label>Folio: {{ folioNumbers[companyId] }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button v-if="!editMode" @click="cancel">Cancel / Reset</b-button>
                        <b-button v-if="editMode" @click="close">Cancel</b-button>
                    </b-col>
                    <b-col style="text-align:center">
                        <b-button variant="warning" @click="deleteEntry">Delete</b-button>
                    </b-col>
                    <b-col style="text-align:right">
                        <b-button variant="primary" @click="saveEntry" :disabled="errorFields.length>0"> Submit
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            <br/><br/>
        </b-overlay>
        <div v-if="batchTransactions.length>0">
            <h2>Saved Transactions</h2>
            <b-table :fields="batchFields" :items="batchTransactions">
            </b-table>
        </div>
    </div>
</template>

<script>
export default {
    name: "EntryForm",
    props: {
        companyId: String,
        folioNumbers: Object,
        hotels: Array,
    },
    data() {
        return {
            state: 'ready',
            dirty: false,
            batchFields: ['transaction_number', 'transaction_date', 'transaction_type', 'check_number', 'deposit_amount', 'deposit_date'],
            batchTransactions: [],
            transaction: {
                id: 0,
                hotel_id: null,
                transaction_number: null,
                transaction_date: null,
                transaction_type: null,
                paid_by: null,
                reference: null,
                check_number: null,
                deposit_amount: null,
                deposit_date: null,
                folio_number: null,
                notes: null,
                processed: null
            },
            errorFields: [],
            editMode: false
        }
    },
    computed: {
        hotelList() {
            let list = [];
            this.hotels.forEach(function (hotel) {
                list.push(
                    {
                        value: hotel.id,
                        text: hotel.hotel_name + ' - [' + hotel.hotel_id + ']'
                    }
                );
            });
            return list;
        },
        transactionTypes() {
            const list = [
                'CO', 'GR', 'HOA', 'PP', 'UTI'
            ]
            let types = [];
            for (let x = 0; x < list.length; x++) {
                types.push({
                    value: list[x],
                    text: list[x]
                });
            }
            return types;
        },
    },
    watch: {
        transaction:
            {
                handler(newVal, oldValue) {
                    this.dirty = true;
                },
                deep: true
            }
    },
    methods: {
        collectFolioNumber(companyId) {
            if (this.folioNumbers[companyId] === undefined) {
                let url = this.currentUrl + '/next-folio/' + companyId
                let promise = axios.get(url);
                promise.then((response) => {
                    this.folioNumbers[companyId] = response.data.next_folio_number;
                });
            }
        },
        todaysDate() {
            let today = new Date().toISOString().slice(0, 10);
            return today;
        },
        deleteEntry() {
            let url = '/admin/proc/transaction/delete';
            let data = {transaction: this.transaction}
            this.state = 'loading';
            let promise = axios.post(url, data);
            promise.then((response) => {
                location.reload();
            });
        },
        saveEntry() {
            this.transaction.folio_number = this.folioNumber;

            let url = '/admin/proc/transaction';
            let data = {transaction: this.transaction}
            this.state = 'loading';
            let promise = axios.post(url, data);
            promise.then((response) => {
                let transaction = response.data;
                this.batchTransactions.push(transaction);
                this.flashSuccess('Transaction: ' + transaction.transaction_number + ' saved');
                this.state = 'ready';
                if (this.editMode) {
                    this.close();
                    location.reload();
                } else {
                    this.cancel();
                }
            });

        },
        close() {
            this.$bvModal.hide('editModal');
        },
        cancel() {
            this.dirty = false;
            this.newFormActivate();
        },
        newFormActivate() {
            if (!this.dirty) {
                const hotelId = (this.hotelList.length > 0) ? this.hotelList[0].value : null;
                this.transaction = {
                    id: 0,
                    hotel_id: hotelId,
                    transaction_number: null,
                    transaction_date: null,
                    transaction_type: null,
                    paid_by: null,
                    reference: null,
                    check_number: null,
                    deposit_amount: null,
                    deposit_date: this.todaysDate(),
                    folio_number: null,
                    notes: null,
                    processed: null
                }
                this.dirty = false;
            }
            this.collectFolioNumber(this.companyId);
        },
        updateFormActivate(item) {
            this.editMode = true;
            this.transaction = item;
            this.folioNumbers[this.companyId] = item.folio_number;
            this.dirty = false;
        },
        isFieldError(field) {
            if (this.transaction[field] === null) {
                return true;
            }
            if (this.transaction[field].toString().trim() === '') {
                return true;
            }
            return false;
        },
        isError(field) {
            const isError = this.isFieldError(field);
            if (isError && !this.errorFields.includes(field)) {
                this.errorFields.push(field);
            }
            if (!isError && this.errorFields.includes(field)) {
                this.errorFields.splice(this.errorFields.indexOf(field), 1);
            }
            return isError;
        },

    },
    created() {
        this.$root.$on('new-entry-form-activate', this.newFormActivate)
        this.$root.$on('update-entry-form-activate', this.updateFormActivate)
    },
    mounted() {
    }


}
</script>

<style scoped>
.error {
    border: 1px solid #ff6666;
}
</style>
